import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import Logo from "./assets/images/trustbadge.png";
import { AppProvider } from "@shopify/shopify-app-remix/react";
import {
  BlockStack,
  Button,
  Card,
  EmptyState,
  Layout,
  Page,
  Text,
} from "@shopify/polaris";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { useEffect } from "react";
import polarisStyles from "@shopify/polaris/build/esm/styles.css?url";
// import { authenticate } from "./shopify.server";
// import { json } from "@remix-run/node";

export const links = () => [{ rel: "stylesheet", href: polarisStyles }];

// export const loader = async ({ request }) => {
//   // try {
//   const { admin } = await authenticate.admin(request);
//   let adminData = await admin.graphql(
//     `#graphql
//     query {
//       shop {
//         email
//         name
//         createdAt
//       }
//     }`,
//   );
//   adminData = (await adminData.json()).data.shop;
//   console.log("admin data", adminData);
//   return json({ ...adminData, apiKey: process.env.SHOPIFY_API_KEY || "" });
// } catch (error) {
//   console.log("error", error);
//   return null;
// }
// };

export default function App() {
  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <IntercomProvider appId="vlh5nk1z">
          <Outlet />
        </IntercomProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  Sentry.captureRemixErrorBoundaryError(error);
  return (
    <html>
      <head>
        <title>Something went wrong</title>
        <Meta />
        <Links />
      </head>
      <body>
        {/* <IntercomProvider appId="vlh5nk1z"> */}
        <AppProvider>
          <Page>
            <Layout>
              <Layout.Section>
                {error.status === 404 ? (
                  <NotFound error={error} />
                ) : (
                  <CustomErrorBoundary error={error} />
                )}
              </Layout.Section>
            </Layout>
          </Page>
        </AppProvider>
        {/* </IntercomProvider> */}
        <Scripts />
      </body>
    </html>
  );
};

function CustomErrorBoundary({ error }) {
  // const { boot } = useIntercom();
  // const loader = useRouteLoaderData("root");
  // console.log(loader);

  // useEffect(() => {
  // if (loader) {
  //   boot({
  //     email: loader?.email || "unknown",
  //     name: loader?.name || "unknown",
  //     createdAt: loader?.createdAt || new Date().toISOString(),
  //     customAttributes: { app: "Super Trust Badges" },
  //     actionColor: "#FF6500",
  //     backgroundColor: "#FF6500",
  //   });
  // }
  //   boot();
  // }, [loader, boot]);
  return (
    <Card>
      <BlockStack inlineAlign="center" gap={"400"}>
        <img src={Logo} alt="Logo" />
        <Text variant="headingLg">Something went wrong</Text>
      </BlockStack>
      {process.env.NODE_ENV === "development" && <pre>{error.stack}</pre>}
    </Card>
  );
}

function NotFound({ error }) {
  return (
    <Card>
      <BlockStack inlineAlign="center" gap={"400"}>
        <img src={Logo} alt="Logo" />
        <Text variant="headingLg">Page Not Found</Text>
        <Button url="/app" variant="primary" size="large">
          Home
        </Button>
      </BlockStack>
      {process.env.NODE_ENV === "development" && <pre>{error.stack}</pre>}
    </Card>
  );
}
